import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import "./custom.css";
const DeleteAccount = React.lazy(() =>
  import("./views/Pages/Login/DeleteAccount.js")
);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/Login/ForgetPassword.js")
);
const Process = React.lazy(() => import("./views/Pages/Login/process"));

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/account-delete"
              name="Delete Account"
              render={() => <DeleteAccount />}
            />
            <Route
              exact
              path="/payment-process-checking"
              name="Process"
              render={() => <Process />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
